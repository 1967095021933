import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import FileUpload from '../../components/FileUpload/FileUpload';
import validate from 'validate.js';
import Button from '../../components/common/Button';

import { api } from '../../services/api';
import { Toast } from '../../services/toast';
import {
  uploadVideo,
  uploadApi,
  uploadImage,
} from '../../services/apiVariables';
import { Spinner } from 'reactstrap';
import { paymentPopupEvent } from '../../components/customEvents/events';
import PaymentPopup from '../../components/paymentPopup';
import SponserSlider from '../../components/SponserSlider';
// import SponserSlider from '../../components/SponserSlider';
import imageCompression from 'browser-image-compression';


const ImageUpload = () => {
  const [previews, setPreviews] = useState({ video: '',image: ''});
  const [error, setError] = useState({ video: '',image: '' });
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState({
    childName: '',
    upiUrl: ''
  })

  // const handleFileUpload = async (file, type) => {
  //   try {
  //     const { type } = file;
  //     const validType = ['video/mp4'];
  //     if (validType?.includes(type)) {
  //       setIsVideoUploading(true);
  //       const formData = new FormData();
  //       formData.append('video', file);
  //       const response = await api({
  //         ...uploadVideo.uploadvideoApi,
  //         body: formData,
  //       });
  //       console.log('res', response)
  //       if(response.status){
  //         setPreviews({ ...previews, video: response.data });

  //       } 
  //       // else if( !response.data.status && !response.data.isPaymentCompleted) {
  //       //   setResponseData({
  //       //     ...responseData,
  //       //     ...response.data
  //       //   })
  //       //   paymentPopupEvent.dispatchEvent()
  //       // }
       
  //       setIsVideoUploading(false);
  //     } else {
  //       Toast({
  //         type: 'error',
  //         message: 'Please choose video',
  //       });
  //       setIsVideoUploading(false)
  //     }
  //   } catch (error) {
  //     // setError((prevErrors) => ({
  //     //   ...prevErrors,
  //     //   [type]: 'Error uploading the video',
  //     // }));
  //   }
  // };

  const handleFileUpload = async (file, type) => {
    if (type === 'image') {
      try {
        const options = {
          maxSizeMB: 1,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);

        const { type } = compressedFile;
        const validType = ['image/jpg', 'image/jpeg', 'image/png'];
        if (validType?.includes(type)) {
          setIsImageUploading(true);
          const formData = new FormData();
          formData.append('image', compressedFile);
          const response = await api({
            ...uploadImage.uploadImageApi,
            body: formData,
          });
          if (response.data) {
            setPreviews({ ...previews, image: response.data });
          }
          setIsImageUploading(false);
        } else {
          Toast({
            type: 'error',
            message: 'Please choose image',
          });
          setIsImageUploading(false);
        }
      } catch (error) {
        // setError((prevErrors) => ({
        //   ...prevErrors,
        //   [type]: 'Error compressing the image',
        // }));
      }
    } else if (type === 'video') {
      try {
        const { type } = file;
        const validType = ['video/mp4'];
        if (validType?.includes(type)) {
          setIsVideoUploading(true);
          const formData = new FormData();
          formData.append('video', file);
          const response = await api({
            ...uploadVideo.uploadvideoApi,
            body: formData,
          });
          if (response.data) {
            setPreviews({ ...previews, video: response.data });
          }
          setIsVideoUploading(false);
        } else {
          Toast({
            type: 'error',
            message: 'Please choose video',
          });
          setIsVideoUploading(false)
        }
      } catch (error) {
        // setError((prevErrors) => ({
        //   ...prevErrors,
        //   [type]: 'Error uploading the video',
        // }));
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = validate(previews, constraints);
    const { image, video } = previews;

    if (validationResult) {
      setError(validationResult);
    } else {
      try {
        setLoading(true);
        const response = await api({
          ...uploadApi.upload,
          body: {
            childVideo: video,
            childPic: image
          },
        });

        if (response.status === 200) {
          Toast({ type: 'success', message: 'Uploaded Successfully' });
          setPreviews({ video: '' });
          setLoading(false);
          navigate(`/vote?_id=${response.data.childId}`)
        } else {
          Toast({ type: 'error', message: response?.error });
          setLoading(false)
        }
      } catch (error) {
        Toast({ type: 'error', message: error?.error });
        setLoading(false)
      }
    }
  };

  return (
    <section className='event-section bg-smoke space'>
      <div className='container'>
        <div className='event-details mb-4'>
          <div
            className='title-area text-center wow fadeInUp'
            data-wow-delay='0.1s'
          >
            <h2 className='sec-title'>
              Upload Your Reel for Contest
            </h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className='row justify-content-center' >
              <div className=' col-lg-6 event-slider-area'>
                <div className='event-img-slider mb-5'>
                  <div className='img-box-1'>
                    <FileUpload
                      handleFileUpload={(file) =>
                        handleFileUpload(file, 'video')
                      }
                      preview={previews.video}
                      title={previews.video !== '' ? 'Change Video' : 'Upload Video'}
                      error={error.video}
                      type='video'
                      mb={100}
                    />
                  </div>
                </div>

              </div>
              <div className=' col-lg-6'>
                <div className='event-location'>
                  <FileUpload
                    handleFileUpload={(file) => handleFileUpload(file, 'image')}
                    preview={previews.image}
                    title={previews.image !== '' ? 'Change Cover Photo' : 'Upload Cover Photo' }
                    type='image'
                    mb= {3}
                    error={error.image}
                  />
                </div>
              </div>
              {loading ? (
                  <div className='event-submit-btn'>
                    <div className='submitbtncls'><Spinner size="sm" /></div>
                  </div>
                ) : (
                  <div className='event-submit-btn'>
                    <Button onClick={handleSubmit}>submit</Button>
                  </div>
                )}
            </div>
          </form>
        </div>
        <SponserSlider />
      </div>
      {/* <PaymentPopup 
        {...responseData}
      /> */}
    </section>
  );
};

export default ImageUpload;

const constraints = {
  video: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  image: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
};
