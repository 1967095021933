import aditya from '../assets/img/sponser/aditya.jpeg';
import vimo from '../assets/img/sponser/1.png';
import muscle from '../assets/img/sponser/2.png';
import kasapp from '../assets/img/sponser/3.png';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SponserSlider = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const sponsors = [ aditya ,vimo , muscle,  kasapp];
  return (
    <section className='service-section my-5'>
      <div>
        <div class='title-area text-center wow fadeInUp' data-wow-delay='0.1s'>
          <h2 class='sec-title'>Our Sponsors</h2>
        </div>
        <Slider {...settings}>
          {sponsors.map((sp) => (
            <div className='row vs-carousel'>
              <div className='col-lg-12'>
                <div className='hpt-client-logo-1-slider-single'>
                  <img src={sp} alt={''} />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default SponserSlider;
