import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { logout } from '../../../../services/utilities';
import './ProfileDropdown.scss';

export const ProfileDropdown = ({ className = '', childName }) => {
  const menuRef = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 550);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth < 550);
  };

  useEffect(() => {
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  const handleClose = (isOpen) => {
    if (isOpen) {
      setTimeout(() => {
        if (menuRef.current.style.opacity === '') {
          menuRef.current.style.opacity = 1;
        }
      }, 200);
    }
  };

  return (
    <div className={`"Wrapper" ${className}`}>
      <Dropdown className='Block' onToggle={handleClose}>
        <Dropdown.Toggle
          variant=''
          id='dropdown-basic'
          className='Toggle position-relative'
          title='Vote Now'
          aria-label='Profile Icon'
        >
          <i
            className={`icon ${isMobile ? 'small-icon' : 'large-icon'}`}
            color='#000'
          ></i>
          {childName && (
            <span className='PersonName'>Hi, {childName}</span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className='Menu' align='end' ref={menuRef}>
          <div className='Menu_Item'>
            <Link to={`/contest`} className='btn no-style' title='Upload'>
              <i className='fa fa-user'></i>
              Join for Contest
            </Link>
          </div>
          <div className='Menu_Item'>
            <Link
              to={`/participants`}
              className='btn no-style'
              title='Upload'
            >
              <i className='fa fa-heart'></i>
              Vote Now
            </Link>
          </div>
          <div className='Menu_Item' onClick={logout}>
            <div className='btn no-style'>
              <i className='fa fa-sign-out-alt'></i>
              Logout
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

ProfileDropdown.propTypes = {
  className: PropTypes.string,
};

ProfileDropdown.defaultProps = {
  className: '',
};
