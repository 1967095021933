import React, { useEffect, useState } from 'react';


import Service from './Service';
import About from './About'
import ServiceTabs from './Isteam';
import AboutSection from './AboutSection';
import Curriculum from './Curriculum';
import Work from './work';
import Gallery from './Gallery';
import Testimonial from './Testimonial';
import Team from './Team';
import Blog from './Blog';
import FormSection from './FormSection';
import Contact from './Contact';
import HeroSection from './Hero';
import SponserSlider from '../components/SponserSlider';
import ParticipantSlider from '../components/ParticipantSlider';
import LoginModel from '../components/LoginModal';
import Popup from '../components/Popup';



const Home = () => {

  const [showPopup, togglePopup] = useState(false)

  useEffect(() => {
    const getPopup = localStorage.getItem('initialPopup')
    if(getPopup){
      togglePopup(false)
    } else {
      togglePopup(true)
      localStorage.setItem('initialPopup', true)
    }
  }, [])

  return (
    <>
      {showPopup &&
        <Popup />
      }
      <HeroSection />
      <ParticipantSlider />
      <About />
      <Service />
      <ServiceTabs />
      <AboutSection />
      <Curriculum />
      <Work />
      <Gallery />
      <Testimonial />
      <Team />
      <Blog />
      <FormSection />
      <Contact />
    </>
  );
};

export default Home;
