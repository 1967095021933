import React, { useEffect, useState } from 'react';
import {
    paymentPopupEvent,
} from '../customEvents/events';
import { useNavigate } from 'react-router-dom';
import { CreateModel } from '../common/Popup/createModel';
import Button from '../common/Button';
import { api } from '../../services/api';
import { Toast } from '../../services/toast';
import { initiatePayment } from '../../services/apiVariables';

const PaymentPopup = ({childName, upiUrl, showPaymentPopup,handleCallback}) => {
    const navigate = useNavigate();
    // const [showPaymentPopup, togglePaymentPopup] = useState(false);
    const [loading, setLoading] = useState(false);

    // const handlePaymentPopup = (type) => {
    //     togglePaymentPopup(type);
    // };

    // useEffect(() => {
    //     paymentPopupEvent.addEventListener(() => handlePaymentPopup(true));

    //     return () => {
    //         paymentPopupEvent.removeEventListener(() => handlePaymentPopup(false));
    //     };
    // }, []);

    const handleSubmit = async () => {
        // togglePaymentPopup(false)
        handleCallback()

    };
    return (
        <CreateModel
            isOpen={showPaymentPopup}
            className='mx-500'
            showCloseBtn = {false}
        >
            <div className='row justify-content-center'>
                <div className='col-xl-10'>
                    <div className='otp-form'>
                        <h3 className='title-header text-center m-0 pb-3'>
                            Pay for Contest
                        </h3>
                        <div>
                            <p>Hi <strong className='text-capitalize'>{childName !== '' ? childName : 'Child'}</strong> ! Join our summer contest for a chance to enjoy exciting activities, challenges, and win awesome prizes</p>
                            <p>Simply pay an entry fee of <strong>Rs. 500</strong> to upload your reel and get started on your journey to victory!</p>
                            <p>Gpay / Phonepe  - +91 9789-443-066</p>
                        </div>
                        <div className='form-group col-12 text-center'>
                            <Button
                                type='button'
                                id='submit-btn'
                                className='otp-submit-btn'
                                onClick={handleSubmit}
                            >
                                {loading ? 'Loading...' : 'I agree'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </CreateModel>
    );
};

export default PaymentPopup;