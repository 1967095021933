import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useParams, useLocation } from 'react-router-dom';
import { CreateModel } from '../common/Popup/createModel';
import validate from 'validate.js';
import FormInput from '../common/FormInput';
import { api } from '../../services/api';
import { Toast } from '../../services/toast';
import { childApi } from '../../services/apiVariables';
import Button from '../common/Button';
import { Spinner } from 'reactstrap';

const OtpModel = ({ setOtpResponse, onShowOtpPopup, showOtpPopup }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const childId = searchParams.get('_id');
  const initialFormValues = {
    mobileNumber: '',
  };
  const [otp, setOtp] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otpError, setOtpError] = useState('');
  const resetForm = () => {
    setFormValues({ ...initialFormValues });
  };
  const handleInputChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationResult = validate(formValues, validationRules);

    if (validationResult) {
      setErrors(validationResult);
    } else {
      try {
        setLoading(true);
        const response = await api({
          ...childApi.registerVote,
          body: {
            ...formValues,
            childId: childId,
          },
        });

        if (response.status === 200) {
          setSessionId(response?.data?.otpResponse?.Details);
          Toast({
            type: 'success',
            message: response?.message,
          });
          setShowOtpInput(true);
          setLoading(false);
        } else {
          Toast({
            type: 'error',
            message: response?.error || 'Try again or call admin',
          });
          setLoading(false);
        }
      } catch (error) {
        Toast({
          type: 'error',
          message: error?.error || 'Try again or call admin',
        });
        setLoading(false);
      }
    }
  };
  const handleVerifyClick = async (e) => {
    e.preventDefault();
    try {
      if (otp.length == 4) {
        setOtpLoading(true);
        const response = await api({
          ...childApi.verifyOtp,
          body: {
            session_id: sessionId,
            otp: otp,
            mobileNumber: formValues.mobileNumber,
          },
        });
        if (response.status === 200) {
          Toast({
            type: 'success',
            message: response.message,
          });
          setOtpLoading(false);
          onShowOtpPopup(false);
          resetForm();
        } else {
          Toast({
            type: 'error',
            message: response?.error || 'try again',
          });
          setOtpLoading(false);
        }
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error?.error || 'try again',
      });
      setOtpLoading(false);
    }
  };
  const handleEditClick = () => {
    setShowOtpInput(false);
    setOtp('');
  };
  const handleOtpChange = (otpValue) => {
    setOtpError('');
    setOtp(otpValue);
  };
  return (
    <CreateModel
      isOpen={showOtpPopup}
      toggle={() => onShowOtpPopup(false)}
      className='mx-500'
    >
      <div className='row justify-content-center'>
        <div className='col-lg-11'>
          <div className='otp-form pb-3'>
            <h2 className='title-header text-center m-0 pb-3'>Vote Now</h2>
            <form>
              <div className='form-group col-lg-12 mb-0'>
                <div
                  className={
                    !showOtpInput ? 'input-without-icon' : 'input-with-icon'
                  }
                >
                  <FormInput
                    type='text'
                    placeholder='Enter a mobile number'
                    icon='fa-phone'
                    name='mobileNumber'
                    label='Mobile Number'
                    value={formValues.mobileNumber}
                    onChange={handleInputChange}
                    error={errors['mobileNumber']}
                    disabled={showOtpInput}
                  />
                  {showOtpInput && (
                    <i
                      className='fas fa-edit edit-icon'
                      onClick={handleEditClick}
                    ></i>
                  )}
                </div>
              </div>

              {!showOtpInput &&
                (loading ? (
                  <div className='form-group col-12 text-center'>
                    <Button className='otp-submit-btn'>
                      <Spinner size='sm' />
                    </Button>
                  </div>
                ) : (
                  <div className='form-group col-12 text-center'>
                    <Button
                      type='button'
                      id='submit-btn'
                      className='otp-submit-btn'
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                ))}

              {showOtpInput && (
                <>
                  <label className='otp-label'>Enter OTP</label>
                  <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    renderSeparator={<span> - </span>}
                    renderInput={(props) => (
                      <input {...props} type='text' min='0' />
                    )}
                    containerStyle='containerstyle'
                    shouldAutoFocus
                  />
                  {otpError && <p className='otp-error'>{otpError}</p>}
                  {otpLoading ? (
                    <div className=' text-center'>
                      <Button className='otp-submit-btn'>
                        <Spinner size='sm' />
                      </Button>
                    </div>
                  ) : (
                    <div className='text-center '>
                      <Button
                        className='otp-submit-btn '
                        onClick={handleVerifyClick}
                      >
                        Verify
                      </Button>
                    </div>
                  )}
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </CreateModel>
  );
};

export default OtpModel;

const validationRules = {
  mobileNumber: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
    format: {
      pattern: /^\d{10}$/,
      message:
        ' should be 10 digits long and contain only numbers',
    },
  },
};
