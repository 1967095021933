import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import placeholderImg from '../assets/img/empty_image_placeholder.jpeg'
import { Link, useNavigate } from 'react-router-dom'
import Button from './common/Button';


const VoteSlider = ({ childList }) => {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: false,
    autoplay: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const handleClick = (_id) => {

    navigate(`/vote?_id=${_id}`)
    window.scrollTo(0, 0);

  }
  return (
    <>
      {(childList && childList.length > 0) ?
        <div className='testi-slider-area mt-20 mb-4'>
          <div class='title-area text-center wow fadeInUp'>
            <h2>Explore Other Contestants</h2>
          </div>
          <Slider {...settings}>
            {childList.map((child) => (
              <div key={child._id} className='row vs-carousel'>
                <div
                  className='wow fadeInUp slick-slide'
                  data-wow-delay='0.1s'
                >
                  <div className='event-box'>
                    <div className='event-img'>
                      <img src={child.childPic || placeholderImg} alt={child.childName} />
                    </div>
                    <div className='event-info bg-white py-4'>
                      <h4 className='event-title'>{child?.childName}</h4>
                      <div className='event-content'>
                        <p className='event-text'>
                          Participated in <span>{child?.contests?.contestName}</span>
                        </p>
                      </div>
                      <div className='two-btns justify-content-center'>
                        <p>
                          <i className='fas fa-heart'></i>
                          <span className='vote-counter'>{child?.votersCount} {child?.votersCount > 1 ? 'Votes' : 'Vote' }</span>
                        </p>
                      </div>
                      <Button className='vote-slider-btn' onClick={() => handleClick(child?._id)}>
                        Vote Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        :
        <></>}
    </>
  );
};

export default VoteSlider;
