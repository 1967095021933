// events.js
const createEvent = () => {
    const listeners = [];
  
    const addEventListener = (callback) => {
      listeners.push(callback);
    };
  
    const removeEventListener = (callback) => {
      const index = listeners.indexOf(callback);
      if (index !== -1) {
        listeners.splice(index, 1);
      }
    };
  
    const dispatchEvent = (data) => {
      listeners.forEach((callback) => {
        callback(data);
      });
    };
  
    return {
      addEventListener,
      removeEventListener,
      dispatchEvent,
    };
  };
  
  export const paymentPopupEvent = createEvent();
  