import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import validate from 'validate.js';
import { api } from '../../services/api';
import { registerApi } from '../../services/apiVariables';
import { Toast } from '../../services/toast';
import FormInput from '../../components/common/FormInput';
import { paymentPopupEvent } from '../../components/customEvents/events';
import PaymentPopup from '../../components/paymentPopup';
import SponserSlider from '../../components/SponserSlider';
import { Spinner } from 'reactstrap';

const RegisterForm = () => {
  const navigate = useNavigate()
  const [errors, setErrors] = useState({});

  const initialFormValues = {
    childName: '',
    dateOfBirth: '',
    mobileNumber: '',
    schoolName: '',
  };
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const [isLoading, setLoading] = useState(false)
  const [responseData, setResponseData] = useState({
    childName: '',
    // upiUrl: ''
  })
  const [showPaymentPopup, togglePaymentPopup] = useState(false);

  const resetForm = () => {
    setFormValues({ ...initialFormValues });
  };
  const handleInputChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { registerFormApi } = registerApi;
    const validationResult = validate(formValues, validationRules);

    if (validationResult) {
      setErrors(validationResult);
    } else {
      try {
        setLoading(true)
        const response = await api({
          ...registerFormApi,
          body: {
            ...formValues,
          }
        });
        if (response.status === 200) {
          const { token, childName } = response.data;
          localStorage.setItem('token', token);
          Toast({
            type: 'success',
            message: 'Successfully Registered for contest.',
          });
          resetForm();
          setLoading(false)
          // if (isPaymentCompleted) {
          //   navigate('/contest')
          // } else {
          setResponseData({
            ...responseData,
            childName: childName,
            // upiUrl: upiUrl
          })
          // paymentPopupEvent.dispatchEvent()
          togglePaymentPopup(true)
          // }

        } else {
          Toast({
            type: 'error',
            message: response?.error || 'Try again or call admin',
          });
          setLoading(false)
        }
      } catch (error) {
        Toast({
          type: 'error',
          message: error?.error || 'Try again or call admin',
        });
        setLoading(false)
      }
    }
  };

  const handleCallback = () => {
    togglePaymentPopup(false)
    navigate('/contest')
  }
  return (
    <>
      <section className='event-section bg-smoke space'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='contact-form-box'>
                <h2 className='contact-title'>Register for Summer Contest</h2>
                <div className='row'>
                  <div className='col-lg-6 col-12'>
                    <div className='contest_image_wrapper mb-4'>
                      <img
                        className='contestImage'
                        src={require('../../assets/img/summercontest.jpeg')} alt='contest'
                      />
                    </div>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <form className='contact-form ' onSubmit={handleSubmit}>
                      <div className='row'>
                        <div className='form-group col-12 '>
                          <FormInput
                            type='text'
                            placeholder='Your Child Name'
                            icon='fa-user'
                            name='childName'
                            label='Child Name'
                            className=""
                            value={formValues.childName}
                            onChange={handleInputChange}
                            error={errors['childName']}
                          />
                        </div>
                        <div className='form-group col-12'>
                          <FormInput
                            type='date'
                            placeholder='choose date'
                            icon='fa-calendar'
                            label='Child Date Of Birth'
                            name='dateOfBirth'
                            value={formValues.dateOfBirth}
                            onChange={handleInputChange}
                            error={errors['dateOfBirth']}
                          />
                        </div>
                        <div className='form-group col-12'>
                          <FormInput
                            type='text'
                            placeholder='Your mobile number'
                            icon='fa-phone'
                            name='mobileNumber'
                            label='Mobile Number'
                            value={formValues.mobileNumber}
                            onChange={handleInputChange}
                            error={errors['mobileNumber']}
                          />
                        </div>

                        <div className='form-group col-12'>
                          <FormInput
                            name='schoolName'
                            type='text'
                            placeholder='Child school name'
                            icon='fa-pencil-alt'
                            label='School Name'
                            value={formValues.schoolName}
                            onChange={handleInputChange}
                            error={errors['schoolName']}
                          />
                        </div>

                        <div className='form-group col-12 d-flex justify-content-end'>
                          {isLoading ? (
                            <div className='event-submit-btn'>
                              <div className='vs-btn wave-btn'><Spinner size="sm" style={{ verticalAlign: 'middle' }} /></div>
                            </div>
                          ) : (
                            <button
                              type='submit'
                              id='submit-btn'
                              className='vs-btn wave-btn'
                            >
                              Register
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <SponserSlider />
        </div>
      </section>
      <PaymentPopup
        {...responseData}
        handleCallback={handleCallback}
        showPaymentPopup={showPaymentPopup}
      />
    </>
  );
};

export default RegisterForm;
const validationRules = {
  childName: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  dateOfBirth: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  schoolName: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  mobileNumber: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
    format: {
      pattern: /^\d{10}$/,
      message:
        ' should be 10 digits long and contain only numbers',
    },
  },
};
