import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../services/api';
import { Toast } from '../../services/toast';
import { contestApi } from '../../services/apiVariables';

const ContestList = () => {
  const [contestList, setContestList] = useState([]);

  const getList = async () => {
    const { contestListApi } = contestApi;

    try {
      const response = await api({
        ...contestListApi,
      });
      return response.data;
    } catch (error) {
     
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getList();
        setContestList(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section className='event-section bg-smoke space'>
        <div className='container'>
        <div class='title-area text-center wow fadeInUp' data-wow-delay='0.1s'>
          <h2 >
            EXPLORE CONTEST
          </h2>
        </div>
          <div className='row gy-70'>
            {contestList.map((contest) => (
              <div
                className='col-lg-4 col-md-6 wow fadeInUp'
                data-wow-delay='0.1s'
                key={contest._id}
              >
                <div className='contest-box'>
                  <div className='contest-img'>
                    <img
                      src={contest.contestImageUrl}
                      alt={contest.contestName}
                    />
                  </div>
                  <div className='contest-content'>
                    <h4 className='event-title'>
                      {contest.contestName}
                    </h4>
                    <div className='contest-description-title'>
                      Description:
                    </div>
                    <ul className='event-info'>
                      {contest.contestDescription.map((desc, index) => (
                        <li key={index}>
                          <i className='fal fa-check-circle'></i> {desc}
                        </li>
                      ))}
                    </ul>
                    <Link
                      to={`/registerForm?_id=${contest._id}&name=${encodeURIComponent(
                        contest.contestName
                      )}`}
                      className='link-btn'
                    >
                       Register Now <i className='far fa-long-arrow-right'></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ContestList;