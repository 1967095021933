import React, { useState } from 'react';
import validate from 'validate.js';
import { useNavigate } from 'react-router-dom';
import FormInput from '../common/FormInput';
import { CreateModel } from '../common/Popup/createModel';
import Button from '../common/Button';
import { api } from '../../services/api';
import { Toast } from '../../services/toast';
import { login } from '../../services/apiVariables';

const Login = ({ onShowLoginPopup, showLoginPopup,triggerPayment }) => {
  const initialFormValues = {
    mobileNumber: '',
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({ ...initialFormValues });

  const resetForm = () => {
    setFormValues({ ...initialFormValues });
  };
  const handleInputChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const validationResult = validate(formValues, validationRules);

    if (validationResult) {
      setErrors(validationResult);
    } else {
      try {
        const response = await api({
          ...login.loginApi,
          body: {
            ...formValues,
          },
        });
        if (response.status === 200) {
          const { token, childName } = response.data;
          localStorage.setItem('token', token);
          Toast({
            type: 'success',
            message: 'Successfully Login',
          });
          resetForm();
          setLoading(false);
          // if (isPaymentCompleted) {
          //   navigate('/contest');
          // } else {
            onShowLoginPopup(false);
            triggerPayment({childName})

          // }

        } else {
          Toast({
            type: 'error',
            message: response?.error || 'Try again or call admin',
          });
          setLoading(false);
        }
      } catch (error) {
        Toast({
          type: 'error',
          message: error?.error || 'Try again or call admin',
        });
        setLoading(false);
      }
    }
  };
  return (
    <>
      <CreateModel
        isOpen={showLoginPopup}
        toggle={onShowLoginPopup}
        showCloseBtn={true}
        className='mx-500'
      >
        <div className='row justify-content-center'>
          <div className='col-xl-10'>
            <div className='otp-form'>
              <h3 className='title-header text-center m-0 pb-3'>
                Login for Contest
              </h3>
              <form>
                <div className='form-group col-12 mb-0'>
                  <FormInput
                    type='text'
                    placeholder='Enter a mobile number'
                    icon='fa-phone'
                    name='mobileNumber'
                    label='Mobile Number'
                    value={formValues.mobileNumber}
                    onChange={handleInputChange}
                    error={errors['mobileNumber']}
                  />
                </div>
                <div className='form-group col-12 text-center'>
                  <Button
                    type='button'
                    id='submit-btn'
                    className='otp-submit-btn'
                    onClick={handleSubmit}
                  >
                    {loading ? 'Loading...' : 'Submit'}
                  </Button>
                </div>
                <div className='signup-description fs-16 pb-3 text-center'>
                  <p className='fs-16 fw-medium reg-link'>
                    Not registered yet?{' '}
                    <a href='/register' className='fw-medium'>
                      Register
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </CreateModel>
    </>
  );
};

export default Login;

const validationRules = {
  mobileNumber: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
    format: {
      pattern: /^\d{10}$/,
      message: ' should be 10 digits',
    },
  },
};
