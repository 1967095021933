import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
// import { IoMdClose } from 'react-icons/io';


const CreateModel = ({
  isOpen,
  toggle,
  title,
  children,
  className,
  closeBtn,
  showCloseBtn,
  ...rest
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={className}
      centered={true}
    >
      <ModalHeader className="border-bottom-0 pt-3">
        {showCloseBtn &&
          <div className="close-button" onClick={() => toggle(false)}>
            <i className="fas fa-times" style={{ fontSize: '24px', color: 'gray' }}></i>
          </div>
        }
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export { CreateModel };
