import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import io from 'socket.io-client';

import VoteSlider from '../../components/VoteSilder';
import OtpModel from '../../components/AuthPopups/Otp';
import Button from '../../components/common/Button';
import { Toast } from '../../services/toast';
import { api } from '../../services/api';
import { childApi } from '../../services/apiVariables';
import config from '../../config';
import placeholderImg from '../../assets/img/empty_image_placeholder.jpeg'
import SponserSlider from '../../components/SponserSlider';
import AnimatedNumber from 'react-animated-counter';

const Vote = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const _id = params.get('_id');

  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [childList, setChildList] = useState([]);
  const [votersCount, setVotersCount] = useState(0)
  const [childData, setChildData] = useState({
    childName: '',
  })

  const getVotersCount = (childId) => {
    const socket = io(config.api.BASE_URL);
    socket.emit('join', { childId }, (error) => {
      if (error) {
      }
    });
    socket.on('votersCount', ({ votersCount }) => {
      setVotersCount(votersCount)
    })
    socket.on('update', ({ votersCount }) => {
      setVotersCount(votersCount)
    })
  }
  useEffect(() => {
    getVotersCount(_id)
  }, [_id]);

  const getChildList = async () => {
    try {
      const { getAllChildApi } = childApi;

      const response = await api({
        ...getAllChildApi,
        url: `${getAllChildApi.url}?childId=${_id}`
      });

      if (response.status === 200) {
        setChildList(response.data);
      } else {
        setChildList([])
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error?.message || 'Failed to fetch data',
      });
    }
  };

  const getChildListById = async () => {
    try {
      const { getChildById } = childApi;

      const response = await api({
        ...getChildById,
        url: `${getChildById.url}/${_id}`,
      });
      if (response && response) {
        setChildData(response.data);
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error?.message || 'Failed to fetch data',
      });
    }
  };

  useEffect(() => {
    if (_id != null) {
      getChildListById();
    }
    getChildList()
  }, [_id]);

  const handleVoteNowClick = () => {
    setShowOtpPopup(true);
  };

  const handleShareClick = async (text) => {
    
    try {
      if (navigator.share) {
        await navigator.share({
          title:  `Vote for ${childData?.childName} \n`,
          text: `🎉 ${childData?.childName} is in the summer contest!\n\nCast your vote now! 🗳️\n\n`,
          url: text
        });
      } else {
        const txt = `🎉 ${childData?.childName} is in the summer contest!\n\nCast your vote now! 🗳️\n\n${text}`
        await navigator.clipboard.writeText(txt);

        Toast({ message: 'URL copied!' });
      }
    } catch (error) {
      console.error('Error sharing message:', error);
    }

  };

  return (
    <section class='team-section bg-smoke space space-extra-bottom'>
      <div class='container'>
        {childData?.childName != '' ?
          <div
            class='row text-center justify-content-center wow fadeInUp'
            data-wow-delay='0.1s'
          >
            <div class='col-xl-6 col-lg-7 col-md-8 col-sm-9'>
              <div class='title-area'>
                <h2 class='sec-title'>Vote for <span className='sec-title-primary'>{childData?.childName}</span></h2>
              </div>
            </div>
          </div>
          :
          <></>
        }
        <div className='vote-section mb-4'>
          <div className='row gy-30 justify-content-between'>
            <div className='col-xl-6 col-lg-6'>
              <div className="dropzone">
                <video
                  controls
                  className="avatar-sm rounded bg-light"
                  alt={"Child Video"}
                  src={childData?.childVideo}
                  style={{ width: "100%", height: "300px" }}
                />
              </div>
            </div>
            <div className='col-xl-6 col-lg-6'>
              <div className='dropzone'>
                <img
                  className="rounded bg-light"
                  alt={"Child Pic"}
                  src={childData?.childPic || placeholderImg}
                  style={{ width: "100%", height: "300px", objectFit: 'contain' }}
                />
              </div>
            </div>
          </div>
          <div className='row mt-20  align-items-center'>
            <div className='col-lg-12'>
              <div className='event-title-box justify-content-between'>
                <div className='title-info'>
                  <h2 className='h2 event-title text-left'>{childData?.childName} </h2>
                  <p className='title-info-content'>Participated in <span>Summer Contest</span></p>
                </div>
                <div className='event-title-box'>
                  <p className='event-content' onClick={() => handleShareClick(window.location.href)}>Share<span className='ps-2'><i className='fas fa-share-alt'></i></span></p>
                </div>
              </div>
            </div>
            <div className='col-lg-12 text-start text-lg-end'>
              <div className='two-btns'>
                <p>
                  <i className='fas fa-heart'></i>
                  <span className='vote-counter'>{votersCount} {votersCount > 1 ? 'Votes' : 'Vote' }</span>
                </p>
                <Button type='button' className="ms-3" onClick={handleVoteNowClick} >
                  Vote Now
                </Button>
              </div>
            </div>
          </div>
        </div>
        <VoteSlider
          childList={childList}
        />
        <SponserSlider />
      </div>

      {showOtpPopup && (
        <OtpModel
          onShowOtpPopup={setShowOtpPopup}
          showOtpPopup={showOtpPopup}
        />
      )}
    </section>
  );
};

export default Vote;
