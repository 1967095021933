import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { api } from '../services/api';
import { Toast } from '../services/toast';
import { childApi } from '../services/apiVariables';
import placeholderImg from '../assets/img/empty_image_placeholder.jpeg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SponserSlider from './SponserSlider';

const ParticipantSlider = () => {
  const navigate = useNavigate()
  const settings = {
    dots: true,
    infinite: false,
    autoplay: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const [childList, setChildList] = useState([]);

  const getChildList = async () => {
    try {
      const { getAllChildApi } = childApi;

      const response = await api({
        ...getAllChildApi,
      });

      if (response.status === 200) {
        setChildList(response.data);
      } else {
        setChildList([])
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error?.message || 'Failed to fetch data',
      });
    }
  };

  useEffect(() => {
    getChildList();
  }, []);

  const handleNavigate = () => {
    navigate('/participants')
  }
  return (
    <>

      <div className='container container-space testi-slider-area mt-20 '>
        {(childList && childList.length > 0) ?
          <>
            <div>
              <div class='title-area text-center wow fadeInUp'>
                <h2>Our Contestants</h2>
              </div>
              <Slider {...settings}>
                {childList.map((child) => (
                  <div key={child._id} className='row vs-carousel '>
                    <div
                      className='col-lg-12 col-md-12 wow fadeInUp slick-slide wow fadeInUp '
                      data-wow-delay='0.1s'
                    >
                      <div className='event-box'>
                        <div className='event-img'>
                          <img
                            src={child.childPic || placeholderImg}
                            alt={child.childName}
                          />
                        </div>
                        <div className='event-info bg-smoke py-3'>
                          <h4 className='event-title text-capitalize'>{child?.childName}</h4>
                          <div className='event-content'>
                            <p className='event-text'>
                              Participated in <span>Summer Contest</span>
                            </p>
                          </div>
                          <div className='two-btns justify-content-center'>
                            <p>
                              <i className='fas fa-heart'></i>
                              <span className='vote-counter'>{child?.votersCount} {child?.votersCount > 1 ? 'Votes' : 'Vote' }</span>
                            </p>
                          </div>
                          <Link to={`/vote?_id=${child._id}`} className='vote-slider-btn'>
                            Vote Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <div className='view-all-btn mt-5'>
                <p onClick={handleNavigate}>View All Contestants <i className='fa fa-arrow-right'></i></p>
              </div>
            </div>
          </>
          :
          <></>
        }
      <SponserSlider />

      </div>

    </>
  );
};

export default ParticipantSlider;
