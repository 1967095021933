import React from 'react';
import { Link } from 'react-router-dom';

const Popup = () => {
  return (
    <>
      <div className="popup-overlay"></div>
      <div className="popup-search-box  show">
        <div className="popup-content">
          <img src={require('../assets/img/summercontest.jpeg')} alt='' />
          <div className="popup-button">
            <Link to="/register" className='vs-btn wave-btn'>
              Register Now
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
