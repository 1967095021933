import TestimonialSlider from '../components/common/CurriculumSlider';
import dot from '../assets/img/icon/dog.png';
import curve from '../assets/img/icon/curve-line.png';
import star from '../assets/img/icon/star-3.png';
import triangle from '../assets/img/icon/triangle.png';

const Curriculum = () => {
  return (
    <>
      <section className='classes-section space' id='class'>
        <div className='container'>
          <div
            className='row text-center justify-content-center wow fadeInUp'
            data-wow-delay='0.1s'
          >
            <div className='col-xl-6 col-lg-7 col-md-8 col-sm-9'>
              <div className='title-area'>
                <span className='sub-title'>Curriculum By Age</span>
                <h2 className='sec-title'>
                  Take The Classes & Start Learning From Today
                </h2>
              </div>
            </div>
          </div>
          <TestimonialSlider />
        </div>
        <div
          className='shape-mockup shapePulse d-none d-xl-block'
          data-top='15%'
          data-left='7%'
          style={{ top: '15%', left: '7%' }}
        >
          <img src={dot} alt='shapes' />
        </div>
        <div
          className='shape-mockup rotate d-none d-hd-block'
          data-bottom='17%'
          data-left='7%'
          style={{ top: '17%', left: '7%' }}
        >
          <img src={curve} alt='shapes' />
        </div>
        <div
          className='shape-mockup rotate z-index-negative'
          data-top='32%'
          data-right='9%'
          style={{ top: '32%', right: '9%' }}
        >
          <img src={star} alt='shapes' />
        </div>
        <div
          className='shape-mockup shapePulse d-none d-hd-block'
          data-bottom='16%'
          data-right='9%'
          style={{ top: '16%', right: '9%' }}
        >
          <img src={triangle} alt='shapes' />
        </div>
      </section>
    </>
  );
};

export default Curriculum;
